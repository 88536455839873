import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

const LinkIcon = ({
  href,
  title,
  icon,
  children,
  className,
}: {
  href: string
  title: string
  icon: IconDefinition
  children?: React.ReactNode
  className?: string
}) => (
  <a
    href={href}
    className={className}
    target="_blank"
    title={title}
    rel="noopener noreferrer"
  >
    {children}
    <FontAwesomeIcon icon={icon} />
  </a>
)

export default LinkIcon
