import React from "react"
import PropTypes from "prop-types"
import Section from "src/components/common/section/section"
import containerStyles from "./footer.module.scss"
import LinkIcon from "src/components/common//linkIcon"
import {
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import "./layout.scss"

const Layout = ({ children }: { children: any }) => {
  return (
    <>
      <div
        style={{
          width: `100%`,
          backgroundColor: `white`,
          padding: 0,
        }}
      >
        <div>{children}</div>
        <footer className={containerStyles.footer}>
          <Section noMinHeight={true}>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <p>
                  © {new Date().getFullYear()}, Built by
                  {` `}
                  <a
                    href="https://www.linkedin.com/in/mondragonjohn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    John Mondragon
                  </a>
                  <br />
                  Follow me on:{" "}
                  <LinkIcon
                    href="https://www.linkedin.com/in/mondragonjohn/"
                    title="LinkedIn"
                    icon={faLinkedin}
                    className={containerStyles.socialLink}
                  />
                  <LinkIcon
                    href="https://twitter.com/JohnMondragonC"
                    title="Twitter"
                    icon={faTwitter}
                    className={containerStyles.socialLink}
                  />
                  <LinkIcon
                    href="https://github.com/raskaman"
                    title="Github"
                    icon={faGithub}
                    className={containerStyles.socialLink}
                  />
                  <br />
                  Some icons by{` `}
                  <a target="_blank" href="https://icons8.com">
                    Icons8
                  </a>
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <p>
                  This responsive web site has been designed, developed, and
                  maintained by John Mondragon. It’s built with the latest front
                  end technology, optimised for a fast page load, hosted on AWS
                  and served via CloudFront. Current Average load is 0.4s.
                </p>
              </div>
            </div>
          </Section>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
