import React from "react"
import containerStyles from "./section.module.scss"

const Section = ({
  children,
  id,
  color,
  noMinHeight,
}: {
  children: React.ReactNode
  id?: string
  color?: string
  noMinHeight?: boolean
}) => {
  let minHeightClass = ""
  if (noMinHeight) {
    minHeightClass = containerStyles.noMinHeight
  }
  return (
    <section
      id={id}
      style={{
        backgroundColor: `${color}`,
      }}
    >
      <div className={containerStyles.section + ` ` + minHeightClass}>
        <div className={containerStyles.box}>{children}</div>
      </div>
    </section>
  )
}

export default Section
